<template>
  <el-header class="header" height="auto">
    <div id="header">
      <div v-if="isMobile === false" class="gery">
        <div class="centerWidth" style="display: flex;width: 1200px">
          <div class="welcome">
            您好，欢迎来到油气人才
            <span v-if="!userId" @click="loginMember">，</span>
            <span
              v-if="!userId"
              style="color: var(--main-color); cursor: pointer"
              @click="loginMember"
              >请登录</span
            >
          </div>
          <!--                <div class="login" @click="loginMember">会员登录</div>-->
          <!--                <div class="register" @click="registerMember">免费注册-->
          <!--                </div>-->

          <div class="right">
            <template>
              <div style="display: flex; cursor: pointer">
                <div>
                  <i
                    class="el-icon-s-home"
                    style="color: var(--main-color)"
                  ></i>
                </div>
                <div @click="dao" class="loginOther">油气岛</div>
                <div @click="expo" class="loginOther">油气展馆</div>
                <div @click="info" class="loginOther">油气知识</div>
                <div @click="commu" class="loginOther">油气社区</div>
                <el-popover
                    placement="bottom"
                    width="45"
                    style="text-align: center"
                    trigger="hover">
                  <img loading="lazy"  src="@/assets/yidong.png" alt="二维码" style="width: 120px; height: auto; display: block; text-align: center;" />
                  <div  slot="reference" class="login">移动端</div>
                </el-popover>
                <div @click="kefu" class="register" style="margin-right: 0">官方客服</div>
                <!--                <div class="login" @click="loginMember">会员登录</div>-->
                <!--                <div class="register" @click="registerMember">免费注册-->
                <!--                </div>-->
              </div>
            </template>
          </div>
        </div>
      </div>
      <div v-if="isMobile === false">
        <div
          class="centerWidth"
          style="display: flex; height:65px; width: 1200px"
        >
          <div class="flex" style="margin-top: 22px;width: 100px;height: 25px">
            <router-link class="logo" to="/">
              <img alt="" src="@/assets/logo.png" />
            </router-link>
          </div>
          <div class="navupper">
               <div class="nav">
              <div class="navlittle">
                <router-link
                    v-for="(item, index) in messageNav"
                    :key="index"
                    :class="[activeName == item.url ? 'active' : '', 'item']"
                    style="cursor: pointer"
                    :to="item.url || ''"
                    exact
                >
                  {{ item.name }}
                </router-link>
              </div>

              <div v-if="userId" style="display: flex;margin:0 0 0 auto;">
                <div>
                  <el-dropdown class="userInfo">
                    <div
                        class="el-dropdown-link"
                        style="display: flex; align-items: center"
                    >
                      <el-avatar :size="32" :src=" userInfo.avatar!==null
                                    ? baseURL + userInfo.avatar
                                    : avator
                                "></el-avatar>

                      <span
                          style="
                          cursor: pointer;
                        margin-left: 10px;
                        font-size: 16px;
                        color: var(--main-color);text-align: right;
                         text-overflow: ellipsis;white-space: nowrap;overflow: hidden;
                     max-width: 140px;width: fit-content;
                      "
                      >你好，{{ nickName }}</span
                      >
                    </div>

                    <el-dropdown-menu slot="dropdown">
                      <!-- <el-dropdown-item class="userIN">
                        {{ userInfo.username }}
                      </el-dropdown-item> -->
                      <el-dropdown-item class="hoverItem">
                        <router-link class="flex" to="/Info">
                          <img
                              alt=""
                              class="icon not"
                              src="@/assets/userIcon.png"
                          />
                          <img
                              alt=""
                              class="icon hover"
                              src="@/assets/userHoverIcon.png"
                          />
                          个人中心
                        </router-link>
                      </el-dropdown-item>
                      <el-dropdown-item
                          class="hoverItem flex"
                          @click.native="outLogin()"
                      >
                        <img alt="" class="icon not" src="@/assets/outIcon.png" />
                        <img
                            alt=""
                            class="icon hover"
                            src="@/assets/outHoverIcon.png"
                        />
                        退出登录
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div v-else class="navlogin" @click="loginMember">登录/注册</div>

            </div>


          </div>
        </div>
      </div>
      <div v-else>
        <div class="centerWidth1 flex1">
          <router-link class="logo1" to="/">
            <img alt="" src="@/assets/logo.png" />
          </router-link>
          <div class="rightSearch1">
            <div class="searchBox1">
              <el-button
                v-if="languageSelect === 'zh'"
                class="searchContent1"
                @click="Search"
                >搜索关键词
              </el-button>
              <el-button
                v-if="languageSelect === 'en'"
                class="searchContent1"
                @click="Search"
                >Search keywords
              </el-button>

              <!--              <el-button class="searchTo1" @click="Search">-->
              <!--                <img src="@/assets/searchIcon.png" alt=""/>-->
              <!--              </el-button>-->
            </div>
          </div>
          <div style="margin-right: 15px">
            <el-popover placement="bottom" trigger="click" width="100">
              <div style="font-size: 14px; line-height: 2">
                <div style="display: flex" @click="PClogin()">
                  <i
                    class="el-icon-message-solid"
                    style="color: gray; margin: auto 20px"
                  ></i
                  >消息
                </div>
                <el-divider></el-divider>
                <div>
                  <div style="display: flex" @click="changeLanguage('en')">
                    <i
                      class="el-icon-mic"
                      style="color: gray; margin: auto 20px"
                    ></i
                    >English
                  </div>
                </div>

                <el-divider></el-divider>
                <div v-if="languageSelect === 'zh'">
                  <div
                    v-if="this.userId !== null"
                    style="display: flex"
                    @click="outLogin()"
                  >
                    <i
                      class="el-icon-switch-button"
                      style="color: gray; margin: auto 20px"
                    ></i
                    >退出
                  </div>
                  <div v-else style="display: flex">
                    <i
                      class="el-icon-loading"
                      style="color: gray; margin: auto 20px"
                    ></i>
                    <router-link class="login" to="/login">登录</router-link>
                  </div>
                </div>
                <div v-if="languageSelect === 'en'">
                  <div
                    v-if="this.userId !== null"
                    style="display: flex"
                    @click="outLogin()"
                  >
                    <i
                      class="el-icon-switch-button"
                      style="color: gray; margin: auto 20px"
                    ></i
                    >Log out
                  </div>
                  <div v-else style="display: flex">
                    <i
                      class="el-icon-loading"
                      style="color: gray; margin: auto 20px"
                    ></i>
                    <view class="login" @click="loginMember">Log in</view>
                  </div>
                </div>
              </div>
              <el-button
                slot="reference"
                icon="el-icon-circle-plus-outline"
                style="
                  border: none;
                  color: var(--main-color);
                  font-size: 25px;
                  width: 15px;
                  margin-right: 10px;
                "
              ></el-button>
            </el-popover>
          </div>
        </div>
        <div v-if="languageSelect === 'zh'" class="centerWidth1 nav1">
          <router-link
            v-for="(item, index) in messageNav"
            :key="index"
            :class="[activeName == item.url ? 'active' : '', 'item']"
            :to="item.url || ''"
            @click="setActive(index)"
          >
            {{ item.name }}
          </router-link>
        </div>
      </div>
      <PClogin :dialogVisible="cancleVisible" @hide="hide"></PClogin>
      <LoginTip
        :dialogVisibleLogin="dialogVisible"
        @hideDialog="hideDialog"
      ></LoginTip>
      <finishTip :dialogVisible="dialog" @hideDialog="hidefinish"></finishTip>
      <InfoTip
        :dialogVisibleInfo="dialogVisibleInfo"
        @hideDialog="hideDialogInfo"
      ></InfoTip>
      <LoginIn
        :dialogVisible="loginIn"
        @hideDialog="hideLoginIn"
        @register="showRegister"
        @forget="forget"
        @logsuccess="logsuccess"
      ></LoginIn>
      <RegisterIn
        :dialogVisible="registerIn"
        @hideDialog="hideregisterIn"
        @login="login"
      ></RegisterIn>
      <forgetIn
        :dialogVisible="forgetIn"
        @hideDialog="hidereforgetIn"
        @login="login"
      ></forgetIn>
    </div>
  </el-header>
</template>
<script>
import {
  logOut,
  getAuthorize,
  introspect,
  // getLoginStatus,
  // deleteUserAgent,
} from "@/api/user";
import Fingerprint2 from "fingerprintjs2";
import PClogin from "@/components/PClogin.vue";
import LoginTip from "@/components/LoginTip.vue";
import LoginIn from "@/views/userlog/LoginIn.vue";
import RegisterIn from "@/views/userlog/RegisterIn.vue";
import forgetIn from "@/views/userlog/forgetIn.vue";
import InfoTip from "@/components/InfoTip.vue";
import finishTip from "@/components/finishTip.vue";

export default {
  components: {
    InfoTip,
    finishTip,
    forgetIn,
    RegisterIn,
    LoginIn,
    LoginTip,
    PClogin,
  },
  data() {
    return {
      options: [
        { value: "1", label: "新建对话", iconClass: "el-icon-chat-dot-round" },
        {
          value: "2",
          label: "历史记录",
          iconClass: "el-icon-document-checked",
        },
        // Add more options as needed
      ],
      dialogVisible: false,
      dialog: false,
      dialogVisibleInfo: false,
      registerIn: false,
      forgetIn: false,
      loginIn: false,
      cancleVisible: false,
      selectedOption: null,
      activeIndex: null,
      isMobile: false,
      languageSelect: "",
      baseURL: this.$utils.baseURL,
      userInfo: localStorage.getItem("userInfo"),
      userId: this.$store.state.userId,
      avator: require("@/assets/avator.png"),
      nickName: "油气人才",
      isUserDataLoaded: false,
      seachInput: "",
      activeName: "",
      navList: this.$store.state.navList,
      messageNav: [
        {
          url: "/Info",
          name: "个人信息",
          nameEn: "Personal information",
        },
        {
          url: "/AIresume",
          name: "GPT助手",
          nameEn: "Personal information",
        },
        {
          url: "/jobCommit",
          name: "我的投递",
          nameEn: "Job Collection",
        },
        {
          url: "/jobCollect",
          name: "我的收藏",
          nameEn: "Job Collection",
        },
        {
          url: "/jobCommunicate",
          name: "浏览记录",
          nameEn: "Job Collection",
        },
        {
          url: "/secure",
          name: "账户安全",
          nameEn: "Account security",
        },
      ],
      count: this.$store.state.MessageCount,
      ThremeCount: 0,
      LikeCount: 0,
      MyCount: 0,
      allCount: this.count,
      primaryTypeid: null,
      token: localStorage.getItem("access_token"),
      navLinks: {
        zh: {
          text: "关于我们", // 中文导航文本
          link: "/language=zh", // 中文导航链接
        },
        en: {
          text: "About Us", // 英文导航文本
          link: "/language=en", // 英文导航链接
        },
      },
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path !== from.path) {
      return;
    }
    next();
  },
  watch: {
    // 监听路由的变化
    $route(to, from) {
      this.activeName = to.path; // 更新 activeName 为当前路由的 path
      console.log(from);
    },
    "$store.state.user"(newValue) {
      this.userInfo = newValue;
    },
    "$store.state.userId"(newValue, oilValue) {
      if (newValue && newValue != oilValue) {
        console.log(newValue, oilValue, "userIf开始");
        this.getUser();
      }
    },
    "$store.state.MessageCount"(newValue) {
      this.count = newValue;
    },
  },
  created() {
    // console.log("========");
    // console.log(this.userInfo);
    this.userInfo=JSON.parse(this.userInfo)
    this.nickName=this.userInfo.nickName

    let murmur;
    let that = this;
    var options = {
      excludes: {
        enumerateDevices: true,
        platform: true,
        addBehavior: true,
        cpuClass: true,
        deviceMemory: true,
        hardwareConcurrency: true,
        colorDepth: true,
        screenResolution: true,
        availableScreenResolution: true,
        indexedDb: true,
        webgl: true,
        plugins: true,
        canvas: true,
        // pixelRatio: true,
      },
    };
    Fingerprint2.get(options, (components) => {
      const values = components.map(function (component, index) {
        if (index === 0) {
          return component.value.replace(/\bNetType\/\w+\b/, "");
        }
        return component.value;
      });
      murmur = Fingerprint2.x64hash128(values.join(""), 31);
      that.murmur = murmur;
      localStorage.setItem("murmur", murmur);
      // that.isLogin();
    });

    if (this.$store.state.access_token) {
      let token = localStorage.getItem("Zyaccess_token");
      introspect(token).then((res) => {
        if (res.data == "false") {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("access_token");
          localStorage.removeItem("userId");
          localStorage.removeItem("typeInfo");
          localStorage.removeItem("access_tokenout");
          localStorage.removeItem("Zyaccess_token");
          this.$store.state.access_token = "";
          this.$store.state.user = {};
          return false;
        }
        if(this.userId){
          this.getUser()
        }

        if (this.$store.state.userId) {
          this.getUser();
        }
      });
    }
    this.getNavList();
    // if (this.$store.state.access_token) {
    //   this.getUser();
    // }
    this.userId = localStorage.getItem("userId");
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    this.activeName = this.$route.path;

    if (this.$route.query.Typeid) this.primaryTypeid = this.$route.query.Typeid;
    if (this.$route.query.id) this.primaryTypeid = this.$route.query.id;

    if (localStorage.getItem("Title")) {
      document.title = localStorage.getItem("Title");
    }
  },
  methods: {
    dao() {
      window.open("https://cn.oilgasdao.com/", "_blank");
    },
    expo() {
      window.open("https://cn.oilgasmall.com/", "_blank");
    },
    info() {
      window.open("https://www.oilgasinfoai.com/", "_blank");
    },
    commu() {
      window.open("https://www.oilgascommunity.com/", "_blank");
    },
    kefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=2957254971134827&webPageTitle=%E5%9C%A8%E7%BA%BF%E5%92%A8%E8%AF%A2", "_blank");
    },
    routerpush(item) {
      console.log(item);
      if (item.url === "/recruitneed") {
        this.loginIn = true;
      } else if (item.url === "/login") {
        if (this.userId) {
          this.dialog = true;
        } else {
          // this.$router.push(item.url);
          let href = window.location.href.split("&code")[0];
          getAuthorize(href).then((res) => {
            window.location.href = res.data.url;
          });
        }
      } else {
        this.$router.push(item.url);
      }
    },
    loginMember() {
      // this.$router.push("/login");
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.href = res.data.url;
      });
      // this.loginIn=true
    },
    forgetPasswordMember() {
      this.forgetIn = true;
    },
    registerMember() {
      this.$router.push("/Register");
      // this.registerIn=true
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    hidefinish() {
      this.dialog = false;
    },
    hideDialogInfo() {
      this.dialogVisibleInfo = false;
    },
    hideLoginIn() {
      this.loginIn = false;
    },
    logsuccess() {
      this.loginIn = false;
      this.userId = this.$store.state.userId;
      window.location.reload();
    },
    showRegister() {
      this.loginIn = false;
      this.registerIn = true;
    },
    forget() {
      this.loginIn = false;
      this.forgetIn = true;
    },
    hideregisterIn() {
      this.registerIn = false;
    },
    hidereforgetIn() {
      this.forgetIn = false;
    },
    login() {
      this.loginIn = true;
      this.registerIn = false;
    },

    showLoginIn() {
      this.loginIn = true;
    },
    PClogin() {
      this.cancleVisible = true;
    },
    hide() {
      this.cancleVisible = false;
    },
    showPopover() {
      this.$refs.customPopover.doToggle(); // 打开弹出框
    },
    selectOption(item) {
      this.selectedOption = item;
      this.$refs.customPopover.doClose(); // 选中选项后关闭弹出框
      // 根据需要执行其他操作
    },
    setActive(index) {
      this.activeIndex = index;
      // 确保导航数据已加载
      this.$nextTick(() => {
        // 获取导航项的父容器
        const navWrapper = document.querySelector(".nav1");
        if (navWrapper) {
          // 获取被点击项的位置（假设每个导航项宽度相同）
          const itemWidth = navWrapper.children[0].offsetWidth;

          // 将被点击项移动到中心位置
          navWrapper.scrollTo({
            left: itemWidth * index - (navWrapper.offsetWidth - itemWidth) / 2,
            behavior: "smooth",
          });
        }
      });
    },
    changeLanguage(language) {
      localStorage.setItem("language", language);
      this.languageSelect = localStorage.getItem("language");
      // 获取当前路由信息
      const currentRoute = this.$router.currentRoute;
      // 获取当前路由的查询参数
      const query = { ...currentRoute.query };
      // 更新语言参数为新的语言
      query.language = language;
      // 使用 $router.replace 更新路由，并传入新的查询参数
      this.$router.replace({ query }).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
      window.location.reload();
    },
    isLogin() {
      let murmur = localStorage.getItem("murmur");
      let idass = this.$store.state.idass;
      let data;
      console.log(idass, "idass");
      if (idass && idass != "undefined") {
        data = {
          userAgent: murmur,
          idass: idass,
        };
      } else {
        data = {
          userAgent: murmur,
        };
      }
      // getLoginStatus(JSON.stringify(data))
      this.$axios
        .post("https://www.oilgasinfoai.com/auth/oauth/getLoginStatus", data)
        .then((res) => {
          console.log(res, "res11111");
          let options = this.$route.query;
          if (
            res.data.data.isLogin == true &&
            !this.$store.state.access_token &&
            !options.code
          ) {
            this.loginMember();
          } else if (
            res.data.data.isLogin == false &&
            res.data.data.type == 2
          ) {
            console.log("isLogin Flase type 2");
            //this.outLogin();
            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userId");
            localStorage.removeItem("typeInfo");
            localStorage.removeItem("access_tokenout");
            localStorage.removeItem("Zyaccess_token");
            localStorage.removeItem("idass");
            this.$store.state.access_token = "";
            this.$store.state.idass = "";
            this.$store.state.user = {};
            this.userId = "";
            this.nickName = "";
          } else if (
            res.data.data.isLogin == false &&
            res.data.data.type == 1
          ) {
            console.log("isLogin Flase type 1");
            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userId");
            localStorage.removeItem("typeInfo");
            localStorage.removeItem("access_tokenout");
            localStorage.removeItem("Zyaccess_token");
            localStorage.removeItem("idass");
            this.userId = "";
            this.nickName = "";
            this.$store.state.access_token = "";
            this.$store.state.idass = "";
            this.$store.state.user = {};
            this.loginMember();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUser() {
      this.$axios.get("/system/job_user/"+this.userId,{
            headers: {
              Authorization: this.token,
            },
          }
      ).then((res) => {
        this.userInfo = res.data.data;
        // console.log(this.userInfo)
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        this.$store.state.user = JSON.stringify(this.userInfo);
        this.nickName=res.data.data.nickName
        // console.log("nicheng ",this.nickName)
        // this.nickName = JSON.stringify(this.userInfo).nickName;
        this.isUserDataLoaded = true;

      }).catch(err=>{
        console.log(err)
      });
    },
    noticeCount() {
      this.noticeCountCom();
      setInterval(() => {
        this.noticeCountCom();
      }, 1000 * 60);
    },
    getNavList() {
      this.$axios
        .get("/system/job_banner/list", {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.navList = res.data.rows;
          this.$store.state.navList = this.navList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 搜索
    Search() {
      sessionStorage.setItem("keywords", this.seachInput);
      this.$emit("keywords", { seachInput: this.seachInput, searchChange: 1 });
      this.$router.push({
        path: "/Search",
        meta: {
          nameEn: "搜索",
        },
        query: { seachInput: this.seachInput, searchChange: 1 },
      });
      // 触发事件
    },
    // 退出登录
    outLogin() {
      logOut()
        .then((res) => {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("access_token");
          localStorage.removeItem("userId");
          localStorage.removeItem("typeInfo");
          localStorage.removeItem("access_tokenout");
          localStorage.removeItem("Zyaccess_token");
          localStorage.removeItem("idass");
          this.$store.state.access_token = "";
          this.$store.state.idass = "";
          this.$store.state.user = {};
          // deleteUserAgent(localStorage.getItem("murmur"))
          let data = {
            userAgent: localStorage.getItem("murmur"),
          };
          this.$axios
            .post(
              "https://www.oilgasinfoai.com/auth/oauth/deleteUserAgent",
              data
            )
            .then(() => {
              localStorage.removeItem("murmur");
              window.location.href = res.data;
            });
        })
        .catch((error) => {
          console.error("退出登录失败:", error);
          this.$message.error("退出登录失败，请重试"); // 提示用户退出登录失败
          // 可以在此处执行其他的错误处理逻辑，例如重新加载页面或者显示其他提示信息
        });
      // this.$axios.get("/system/job_user/logOut" , {
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: this.token,
      //   },
      // })
      //     .then((res) => {
      //       console.log(res)
      //       localStorage.removeItem("userInfo");
      //       localStorage.removeItem("access_token");
      //       localStorage.removeItem("userId");
      //       localStorage.removeItem("typeInfo");
      //       this.$store.state.access_token = "";
      //       this.$store.state.user = {};
      //       this.$message({
      //         message: "退出登录成功！", //提示的信息
      //         type: "success", //类型是成功
      //         duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
      //         onClose: () => {
      //           //跳转页面或执行方法
      //           location.reload();
      //         },
      //       });
      //     }).catch(error=>{
      //   console.log(error)
      // })
    },
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
};
</script>
<style lang="scss" scoped>
#header {
  background: #fff;

  .gery {
    background: #f7f7f7;
    border-bottom: 1px solid #eeeeee;
    height: 40px;

    .centerWidth {
      width: 1280px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;

      .welcome {
        font-size: 14px;
        align-items: center;
        justify-content: center;
        color: #666666;
      }

      .active {
        color: var(--main-color);
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > div {
          margin-left: 1.67vw;
        }

        .message {
          .el-dropdown {
            .el-icon-message-solid {
              font-size: 20px;
            }

            .el-badge__content {
              border: none;
            }

            .el-dropdown-menu__item {
              margin-bottom: 10px;
            }

            .el-dropdown-link {
              cursor: pointer;
            }

            .el-icon-arrow-down {
              font-size: 14px;
              color: #666666;
              line-height: 40px;
            }

            .demonstration {
              display: block;
              color: #8492a6;
              font-size: 14px;
              margin-bottom: 1.8vh;
            }
          }
        }

        .userInfo {
          .el-dropdown-link {
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;

            .el-avatar {
              margin-right: 10px;
            }
          }
        }

        .login,
        .forgetPassword {
          color: #666666;
          font-size: 14px;
          margin: 0 18px;
          position: relative;
        }
        .register {
          color: #666666;
          font-size: 14px;
          margin: 0 0 auto 18px;
          position: relative;
        }
        .loginOther {
          color: var(--main-color);
          font-size: 14px;
          margin: 0 18px;
          position: relative;
        }

        .login:hover,
        .register:hover,
        .forgetPassword:hover {
          color: var(--main-color);
        }

        .login::after {
          content: " ";
          background: #666666;
          width: 1px;
          height: 12px;
          position: absolute;
          right: -18px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }
        .loginOther::after {
          content: " ";
          background: #666666;
          width: 1px;
          height: 12px;
          position: absolute;
          right: -18px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }

        .chooseLanguage {
          color: #666666;
          border-color: #999999;
          background-color: inherit;
          height: 30px;
          line-height: 30px;
          padding-top: 0;
          padding-bottom: 0;
          font-size: 14px;
        }

        .chooseLanguage:focus,
        .chooseLanguage:hover {
          color: #666666;
          border-color: #999999;
          background-color: inherit;
        }
      }
    }
  }

  .centerWidth.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    padding-bottom: 16px;

    .logo {
      width: 145px;
      height: 32px;
    }

    .rightSearch {
      flex: 1;
      margin-left: 80px;
      margin-right: 140px;

      .searchBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: 1px solid var(--main-color);

        .searchContent {
          border-radius: 0;
          color: #999999;
          height: 54px;

          ::v-deep .el-input__inner {
            height: 100%;
          }

          ::v-deep .el-input__inner::placeholder {
            color: #999;
          }
        }

        .searchTo {
          width: 96px;
          height: 54px;
          border: none;
          background: var(--main-color);
          border-radius: 0;

          img {
            width: 21px;
            height: 21px;
          }
        }
      }
    }
  }

  .centerWidth1.flex1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-bottom: 8px;

    .logo1 {
      width: 30%;
      margin-left: 2%;
      height: auto;
    }

    .el-button {
      padding: 1px 2px;
    }

    .rightSearch1 {
      flex: 1;
      margin-left: 5%;

      .searchBox1 {
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-radius: 15px;
        border: 1px solid #999999;

        .searchContent1 {
          float: left;
          width: 100%;
          border-radius: 15px;
          color: #999999;
          background-color: rgb(100, 98, 98, 0.1);
          height: 25px;

          ::v-deep .el-input__inner {
            height: 100%;
            border-radius: 15px;
            background-color: rgb(100, 98, 98, 0.1);
            padding: 0 5px;
          }

          ::v-deep .el-input__inner::placeholder {
            color: #999;
            font-size: 8px;
          }
        }

        .searchTo1 {
          width: 25%;
          height: 25px;
          border: none;
          background: var(--main-color);
          border-radius: 0;

          img {
            width: 16px;
            height: 16px;
            align-items: center;
          }
        }
      }
    }
  }

  .navupper {
    display: flex;
    width: 1140px;
    justify-content: space-between;
    align-items: center;
  }
  .navlittle {
    margin:auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width:80%;
    padding: 0 15px;
    height: 35px;
    font-size: 16px;
    font-weight: 500;
    opacity: 1;
    line-height: 35px;
    color: #131313;

    > .item {
      padding-top: 0;
      flex: 1;
      text-align: center;
    }

    .item:hover {
      color: #2168DB;

    }
    .active {
      color: #2168DB;
      position: relative;
    }

    .active::after {
      content: "";
      margin-top: 2px;
      width: 30%;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 35%;
      background:#2168DB;
    }

    .after {
      position: relative;
    }

    .after::before {
      content: "";
      width: 1px;
      height: 16px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      background: #999999;
    }
  }
  .nav{
    width:100%;
    margin:auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navlogin {
    border-radius: 63px;
    text-align: center;
    height: 26px;
    line-height: 46px;
    background:#2168DB;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    border: #2168DB 1px solid;
    margin-top: 5px;
    display: flex;
    align-items: center;

    padding:20px 25px;
    opacity: 1;
  }
  .navlogin:hover {
    color:  #2168DB;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .nav1 {
    display: flex;
    align-items: center;
    height: 28px;
    line-height: 15px;
    font-size: 14px;
    overflow-x: auto; /* 开启水平滚动 */
    white-space: nowrap; /* 防止导航项换行 */
    &::-webkit-scrollbar {
      display: none; /* 显示滚动条 */
    }

    > .item {
      flex: 1;
      margin: 0 5px; /* 添加间距 */
      text-align: center;
    }

    .active {
      color: var(--main-color);
      position: relative;
    }

    .active::after {
      content: "";
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: -5px;

      margin-top: 5px;
      left: 0;
      background: var(--main-color);
    }

    .after {
      position: relative;
      margin-right: 5px;
    }

    .after::before {
      content: "";
      width: 1px;
      height: 10px;
      position: absolute;
      left: -5px;
      top: 1px;
      background: #999999;
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  width: 145px;
  height: 32px;
  margin-top: 12px;

  img.icon {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  .icon.hover {
    display: none;
  }
}

.hoverItem:hover {
  .icon.hover {
    display: inline-block;
  }

  .icon.not {
    display: none;
  }
}

::v-deep.el-header {
  padding: 0;
}

::v-deep .el-popover .el-popper {
  min-width: 100px !important;
}

::v-deep.el-dropdown-menu__item:focus,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #fff;
  color: var(--main-color);
}

::v-deep.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 2px 0;
}

.userIN:not(.is-disabled):hover {
  background: none;
  color: #666;
}

.el-link.el-link--default:hover {
  color: var(--main-color);
}
</style>
