import { render, staticRenderFns } from "./User.vue?vue&type=template&id=31411330&scoped=true"
import script from "./User.vue?vue&type=script&lang=js"
export * from "./User.vue?vue&type=script&lang=js"
import style0 from "./User.vue?vue&type=style&index=0&id=31411330&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31411330",
  null
  
)

export default component.exports